var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router';
// @mui
import { Container } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import CustomBreadcrumbs from '../../components/custom-breadcrumbs';
import { useSettingsContext } from '../../components/settings';
import { dispatch, useSelector } from '../../redux/store';
import BookForm from '../../sections/@dashboard/Books/BookForm';
import { getOneBook } from '../../redux/slices/books/actions';
// import BookForm from '../../sections/@dashboard/Configuration/Levels/LevelForm';
// ----------------------------------------------------------------------
export default function BooksEdit() {
    var themeStretch = useSettingsContext().themeStretch;
    var id = useParams().id;
    useEffect(function () {
        if (id) {
            dispatch(getOneBook(id));
        }
    }, [id]);
    var book = useSelector(function (state) { return state.books; }).book;
    return (_jsxs(_Fragment, { children: [_jsx(Helmet, { children: _jsx("title", { children: " Book: Edit " }) }), _jsxs(Container, __assign({ maxWidth: themeStretch ? false : 'lg' }, { children: [_jsx(CustomBreadcrumbs, { heading: "Edit Book", links: [
                            {
                                name: 'Books',
                                href: PATH_DASHBOARD.books.list.root,
                            },
                            { name: 'Edit Book' },
                        ] }), _jsx(BookForm, { isEdit: true, currentBook: book || undefined }, new Date().toISOString())] }))] }));
}
